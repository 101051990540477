import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { BASE_URL } from 'src/app/@core/@utills/constant';
import { CookieService } from 'ngx-cookie';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class CommonService {
  service;
  public serverURL = BASE_URL + '/api/';
  noWhitespaceValidator: ValidatorFn;
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private router: Router,
    private cookie: CookieService,
    private metaService: Meta,
    public titleService: Title
  ) {}

  getJsonCookie(name) {
    let data = this.cookie.get(name);
    if (data) return JSON.parse(data);
    return null;
  }
  getCookie(name) {
    return this.cookie.get(name);
  }
  setCookie(name, value) {
    if (this.isBrowser()) {
      return this.cookie.put(name, value);
    }
  }
  setStrCookie(name, value) {
    if (this.isBrowser()) {
      return this.cookie.put(name, JSON.stringify(value));
    }
  }
  deleteAllCookies() {
    this.cookie.removeAll({
      path: '/',
    });
  }

  isAutherize(): boolean {
    if (this.getCookie('accesstoken') && this.getJsonCookie('horsebnbuser')) {
      return true;
    } else {
      return false;
    }
  }
  isAgent(): boolean {
    if (
      this.getCookie('accesstoken') &&
      this.getJsonCookie('horsebnbuser') &&
      this.getJsonCookie('horsebnbuser').user_type == 2
    ) {
      return true;
    } else if (!this.getCookie('accesstoken')) {
      return true;
    } else {
      return false;
    }
  }
  isInvestorAdmin(): boolean {
    if (
      this.getCookie('accesstoken') &&
      this.getJsonCookie('horsebnbuser') &&
      this.getJsonCookie('horsebnbuser').id == 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  isBrowser() {
    if (isPlatformBrowser(this._platformId)) {
      return true;
    }
    return false;
  }

  navigate(url) {
    this.router.navigateByUrl(url);
  }

  setTitle(newTitle: string, removeHorse = false) {
    this.titleService.setTitle(
      removeHorse ? newTitle : 'HorseBnB: ' + newTitle
    );
  }
  setMetaData(title?, desc?, image?) {
    if (desc) {
      this.metaService.updateTag({
        name: 'description',
        content: desc,
      });
      this.metaService.updateTag({
        property: 'og:description',
        content: desc,
      });
      this.metaService.updateTag({
        property: 'twitter:description',
        content: desc,
      });
    }
    if (title) {
      this.metaService.updateTag({
        name: 'title',
        content: title,
      });
      this.metaService.updateTag({
        property: 'og:title',
        content: title,
      });
      this.metaService.updateTag({
        property: 'twitter:title',
        content: title,
      });
    }
    if (image) {
      this.metaService.updateTag({
        name: 'image',
        content: image,
      });
      this.metaService.updateTag({
        property: 'og:image',
        content: image,
      });
    }
  }
}
