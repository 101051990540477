import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  updateDetails = new Subject<boolean>();

  public loginSubject = new BehaviorSubject<boolean>(false);
  loginSubjectObservable = this.loginSubject.asObservable();

  public verifyEmailSubject = new BehaviorSubject<boolean>(null);
  verifyEmailSubjectObservable = this.verifyEmailSubject.asObservable();

  public unreadMsgSubject = new BehaviorSubject<boolean>(false);
  unreadMsgSubjectObservable = this.unreadMsgSubject.asObservable();
  
  public forbiddenSubject = new BehaviorSubject<boolean>(false);
  forbiddenSubjectObservable = this.forbiddenSubject.asObservable();

  public loginPopupSubject = new BehaviorSubject<boolean>(false);
  loginPopupSubjectObservable = this.loginPopupSubject.asObservable();

  public loginPopupBookingSubject = new BehaviorSubject<boolean>(false);
  loginPopupBookingObservable = this.loginPopupBookingSubject.asObservable();

  public chatProfileSubject = new BehaviorSubject<string>('false');
  chatProfileObservable = this.chatProfileSubject.asObservable();

  constructor(){}
  
  changeLogin(value): void {
    this.loginSubject.next(value);
  }
  changeForbidden(value): void {
    this.forbiddenSubject.next(value);
  }
  changeLoginPopup(value): void {
    this.loginPopupSubject.next(value);
  } 
  changeLoginPopupBooking(value): void {
    this.loginPopupBookingSubject.next(value);
  }
  verifyEmail(token){
    this.verifyEmailSubject.next(token)
  }
  changeunreadMsg(value): void {
    this.unreadMsgSubject.next(value);
  }

  chatProfileDetect(value): void {
    this.chatProfileSubject.next(value);
  }
}