import { Injectable } from '@angular/core';
import { CustomApiService } from 'src/app/@core/@services/api.service';
import { CHANGE_PASSWORD, CURRENT_USER_SHOW, CURRENT_USER_UPDATE, FETCH_STRIPE_DETAILS, GENERATE_ACCOUNT_TOKEN, GENERATE_BANK_ACCOUNT_TOKEN, RESEND_EMAIL_VERIFICATION, STRIPE_ACCOUNT_CREATE, STRIPE_ACCOUNT_UPDATE, GENERATE_BANK_TOKEN } from 'src/app/@core/@utills/api-constant';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private apiService: CustomApiService) { }

  getUserData(request) {
    return this.apiService.httpRequest(CURRENT_USER_SHOW, request);
  }
  editUser(request) {
    return this.apiService.httpRequest(CURRENT_USER_UPDATE, request);
  }
  resendEmailVarification(request) {
    return this.apiService.httpRequest(RESEND_EMAIL_VERIFICATION, request);
  }
  uploadProfileImage(request) {
    return this.apiService.httpRequest(RESEND_EMAIL_VERIFICATION, request);
  }
  changePassword(request) {
    return this.apiService.httpRequest(CHANGE_PASSWORD, request);
  }

  fetchStripeDetails(request) {
    return this.apiService.httpRequest(FETCH_STRIPE_DETAILS, request);
  }
  createAccountToken(request) {
    return this.apiService.httpRequest(GENERATE_ACCOUNT_TOKEN, request);
  }
  createBankAccountToken(request) {
    return this.apiService.httpRequest(GENERATE_BANK_ACCOUNT_TOKEN, request);
  }
  createStripeAccount(request) {
    return this.apiService.httpRequest(STRIPE_ACCOUNT_CREATE, request);
  }
  updateStripeAccount(request) {
    return this.apiService.httpRequest(STRIPE_ACCOUNT_UPDATE, request);
  }

  /**
 * Genrate bank account token
 * MKR
 */
  createBankToken(request) {
    return this.apiService.httpRequest(GENERATE_BANK_TOKEN, request);
  }

}
