export const FAKE_API = false;

// sudo ssh -i homeease.pem ubuntu@3.136.247.60
// sudo ssh -i homeease.pem ubuntu@3.131.5.132

// sudo ssh -i horsebnb.pem ubuntu@3.128.12.2

// stag paths
// https://homeease.pro:8005/api/user-dashboard
// export const BASE_URL = 'http://localhost:3001';
// http://3.136.247.60:3001

/*
// dev serverpk_live_96GbT1BjbZ8Cao1XjtL3YBgX00wcpz2Ive
export const BASE_URL = 'http://3.136.247.60:3001';
export const UPLOAD_IMAGE_URL = 'http://3.136.247.60:3001/api/upload-image';
export const IMAGE_URL = 'http://3.136.247.60:3001/photos/thumb/';
export const LARGE_IMAGE_URL = 'http://3.136.247.60:3001/photos/thumb/';
export const MEDIUM_IMAGE_URL = 'http://3.136.247.60:3001/photos/thumb/medium/';
export const SMALL_IMAGE_URL = 'http://3.136.247.60:3001/photos/thumb/small/';
export const SOCKET_BASE_URL = 'http://3.136.247.60:3001';
export const HOME_PAGE_URL = 'http://3.136.247.60:8001';
*/

// // live server
export const BASE_URL = 'https://horsebnb.com:3000/v1/api/';
export const ADMIN_BASE_URL = 'https://horsebnb.com:3000/v1/api/admin/';
export const UPLOAD_IMAGE_URL = 'https://horsebnb.com:3000/v1/api/upload/aws';
export const UPLOAD_IMAGE_URL1 = 'https://horsebnb.com:3000/v1/api/upload/sharetribe-images-to-aws';
export const UPLOAD_FILE_URL = "https://horsebnb.com:3000/api/upload-file";
export const IMAGE_URL = 'https://horsebnb.s3.us-east-2.amazonaws.com/Uploads/Images/Original/';
export const LARGE_IMAGE_URL = 'https://horsebnb.s3.us-east-2.amazonaws.com/Uploads/Images/Original/';
export const MEDIUM_IMAGE_URL = 'https://horsebnb.s3.us-east-2.amazonaws.com/Uploads/Images/Medium/';
export const SMALL_IMAGE_URL = 'https://horsebnb.s3.us-east-2.amazonaws.com/Uploads/Images/Small/';
export const HOME_PAGE_URL = 'https://horsebnb.com:3000';
//export const HOME_PAGE_URL = 'http://3.131.5.132:8083';
export const CHAT_BASE_URL = 'https://horsebnb.com:3000/v1/api/';
export const SOCKET_BASE_URL = 'https://horsebnb.com:3000/';

// // // new stag server
// export const BASE_URL = 'https://horsebnb.com:3001/v1/api/';
// export const ADMIN_BASE_URL = 'https://horsebnb.com:3001/v1/api/admin/';
// export const UPLOAD_IMAGE_URL1 = 'https://horsebnb.com:3001/v1/api/upload/sharetribe-images-to-aws';
// export const UPLOAD_IMAGE_URL = 'https://horsebnb.com:3001/v1/api/upload/aws';
// export const UPLOAD_FILE_URL =  "https://horsebnb.com:3001/api/upload-file";
// export const IMAGE_URL = 'https://horsebnb.s3.us-east-2.amazonaws.com/Uploads/Images/Original/';
// export const LARGE_IMAGE_URL = 'https://horsebnb.s3.us-east-2.amazonaws.com/Uploads/Images/Original/';
// export const MEDIUM_IMAGE_URL = 'https://horsebnb.s3.us-east-2.amazonaws.com/Uploads/Images/Medium/';
// export const SMALL_IMAGE_URL = 'https://horsebnb.s3.us-east-2.amazonaws.com/Uploads/Images/Small/';
// export const HOME_PAGE_URL = 'https://horsebnb.com:3001';
// // export const HOME_PAGE_URL = 'http://3.131.5.132:8083';
//  export const CHAT_BASE_URL = 'https://horsebnb.com:3001/v1/api/';
// export const SOCKET_BASE_URL = 'https://horsebnb.com:3001/';


export const GOOGLE_API_KEY =
  '338473084712-540tdd8o4tdki9sdluq04ktrnmo0tenk.apps.googleusercontent.com';

export const MODAL_CONFIG_OPTIONS = {
  centered: true,
};

//Live-details
export const SK_TEST_KEY = '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';
export const PK_TEST_KEY = 'pk_live_96GbT1BjbZ8Cao1XjtL3YBgX00wcpz2Ive';

//Stripe Test-details
//export const SK_TEST_KEY = 'sk_test_51GORBDC4l8u78p9aJ7J0H5jMV8zmRT5SxwsivS4Qph56WytNEorjuIDwaTzU97WTSQEoZjamw4J8XhSP9FQqTriy00SpHN51YK';
//export const PK_TEST_KEY = 'pk_test_51GORBDC4l8u78p9a0BAHlHOmP571udQEbEmNhV1F70CRLDeYu8h5FHhV3lqK8xaJHtyTE9VplOHFpu8TwnO6k36s00WYoSVXYp';

//CTS Stripe Test-details
//export const SK_TEST_KEY = 'sk_test_51KnG62H5fGleuJKR4WfHjAUYoK6jfjVn4H2sKza5GlfbBZjGIaNEMaRdrXSYmZP5gjgKNwCgrgHO1q3RHeAuK1qT00kpF66LA8';
//export const PK_TEST_KEY = 'pk_test_51KnG62H5fGleuJKRt04pqcgmDFVrytVfzGF4SjJGEaCNfeHhWqWofQMQhQFOrQOsnOQjrFCFWo8LbvatmvzxKlTm00ul9cB3SB';




export const STRIPE_FILE_UPLOAD = 'https://horsebnb.com/stripe/stripe-file-upload.php';
// export const STRIPE_FILE_UPLOAD = 'https://horsebnb.com/stripe/stripe-file-upload.php';


export const BS_CONFIG = { dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false, viewMode: "months", minViewMode: "months", isAnimated: true, adaptivePosition: true, displayOneMonthRange: true };
export const BS_CONFIG_MONTH = { showWeekNumbers: false, viewMode: "months", minMode: 'month', minViewMode: "months", isAnimated: true, adaptivePosition: true, };

export const MONTH_LIST = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const MONTH_LIST_Dummy = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const SHORT_TERM_PROPERTIES = [
  {
    id: 1, name: 'Title', showTick: 0,
    showContinue: 0, link: '/create-stall/step1'
  },
  {
    id: 3, name: 'Stalls', showTick: 0,
    showContinue: 0, link: '/create-stall/step3'
  },
  {
    id: 5,
    name: 'Location',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step5',
  },
  {
    id: 6,
    name: 'Amenities',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step6',
  },
  {
    id: 7,
    name: 'Photos',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step7',
  },
  {
    id: 8,
    name: 'Description ',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step8',
  },
  {
    id: 9,
    name: 'Profile Photo',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step9',
  },
  // {
  //   id: 10,
  //   name: 'Booking Settings',
  //   showTick: 0,
  //   showContinue: 0,
  //   link: '/create-stall/step10',
  // },
  {
    id: 14,
    name: 'Check in and Check out',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/checkin-and-checkout',
  },
  {
    id: 15,
    name: 'Agreement',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/sucessfull-hosting',
  },
  {
    id: 11,
    name: 'Calendar Availability',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step11/1',
  },
  {
    id: 12,
    name: 'Pricing',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step12',
  },
  {
    id: 13,
    name: 'Stripe Connect',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step13',
  },
];
export const MONTHLY_PROPERTIES = [
  { id: 1, name: 'Title', link: '/create-stall/step1' },
  // { id: 2, name: 'Property', link: '/create-stall/step2' },
  { id: 3, name: 'Stalls', link: '/create-stall/step3' },
  {
    id: 5,
    name: 'Location',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step5',
  },
  {
    id: 6,
    name: 'Amenities',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step6',
  },
  {
    id: 7,
    name: 'Photos',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step7',
  },
  {
    id: 8,
    name: 'Description ',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step8',
  },
  {
    id: 9,
    name: 'Profile Photo',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step9',
  },
  // {
  //   id: 10,
  //   name: 'Booking Settings',
  //   showTick: 0,
  //   showContinue: 0,
  //   link: '/create-stall/step10',
  // },
  // {
  //   id: 14,
  //   name: 'Check in and Check out',
  //   showTick: 0,
  //   showContinue: 0,
  //   link: '/create-stall/checkin-and-checkout',
  // },
  {
    id: 15,
    name: 'Agreement',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/sucessfull-hosting',
  },
  {
    id: 16,
    name: 'Calendar Availability',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/availability/2',
  },
  {
    id: 12,
    name: 'Pricing',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step12',
  },
  {
    id: 13,
    name: 'Stripe Connect',
    showTick: 0,
    showContinue: 0,
    link: '/create-stall/step13',
  },
];
export const GUEST_PROPERTIES = [
  { id: 1, name: 'Title', link: '/create-guest/step1' },
  { id: 3, name: 'Rooms', link: '/create-guest/step3' },
  // { id: 4, name: 'Bathrooms', link: '/create-guest/step4' },
  {
    id: 5,
    name: 'Location',
    showTick: 0,
    showContinue: 0,
    link: '/create-guest/step5',
  },
  {
    id: 6,
    name: 'Amenities',
    showTick: 0,
    showContinue: 0,
    link: '/create-guest/step6',
  },
  {
    id: 7,
    name: 'Photos',
    showTick: 0,
    showContinue: 0,
    link: '/create-guest/step7',
  },
  {
    id: 8,
    name: 'Description ',
    showTick: 0,
    showContinue: 0,
    link: '/create-guest/step8',
  },
  {
    id: 9,
    name: 'Profile Photo',
    showTick: 0,
    showContinue: 0,
    link: '/create-guest/step9',
  },
  // {
  //   id: 10,
  //   name: 'Booking Settings',
  //   showTick: 0,
  //   showContinue: 0,
  //   link: '/create-guest/step10',
  // },
  {
    id: 14,
    name: 'Check in and Check out',
    showTick: 0,
    showContinue: 0,
    link: '/create-guest/checkin-and-checkout',
  },
  {
    id: 15,
    name: 'Agreement',
    showTick: 0,
    showContinue: 0,
    link: '/create-guest/sucessfull-hosting',
  },

  {
    id: 11,
    name: 'Calendar Availability',
    showTick: 0,
    showContinue: 0,
    link: '/create-guest/step11/1',
  },
  {
    id: 12,
    name: 'Pricing',
    showTick: 0,
    showContinue: 0,
    link: '/create-guest/step12',
  },
  {
    id: 13,
    name: 'Stripe Connect',
    showTick: 0,
    showContinue: 0,
    link: '/create-guest/step13',
  },
];

export const EXPERIENCE_PROPERTIES = [
  {
    id: 1, name: 'Title', showTick: 0,
    showContinue: 0, link: '/add-experience/step1'
  },
  {
    id: 2, name: 'Location & Your Idea', showTick: 0,
    showContinue: 0, link: '/add-experience/step2'
  },
  // {
  //   id: 3, name: 'Basic Information', showTick: 0,
  //   showContinue: 0, link: '/add-experience/step3'
  // },
  {
    id: 4, name: 'Description', showTick: 0,
    showContinue: 0, link: '/add-experience/step4'
  },
  {
    id: 5,
    name: 'Photos',
    showTick: 0,
    showContinue: 0,
    link: '/add-experience/step5',
  },
  {
    id: 6,
    name: 'Booking Settings',
    showTick: 0,
    showContinue: 0,
    link: '/add-experience/step6',
  },
  {
    id: 7,
    name: 'Slots Availability',
    showTick: 0,
    showContinue: 0,
    link: '/add-experience/step7',
    link1: '/add-experience/step11',
  },
  {
    id: 20,
    name: 'Calendar Availability',
    showTick: 0,
    showContinue: 0,
    link: '/add-experience/calender-availability',
  },
  {
    id: 8,
    name: 'Pricing',
    showTick: 0,
    showContinue: 0,
    link: '/add-experience/step8',
  },
  {
    id: 9,
    name: 'Stripe Connect',
    showTick: 0,
    showContinue: 0,
    link: '/add-experience/step9',
  },

];

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';

// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';

// The backend automatically expire the transaction.
export const TRANSITION_EXPIRE = 'transition/expire';

// Admin can also cancel the transition.
export const TRANSITION_CANCEL = 'transition/cancel';
export const TRANSITION_CANCEL_CUSTOMER = 'transition/cancel-customer';
export const TRANSITION_CANCEL_PROVIDER = 'transition/cancel-provider';

// The backend will mark the transaction completed.
export const TRANSITION_COMPLETE = 'transition/complete';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';
export const TRANSITION_CONNECT_STRIPE_EMAIL = 'transition/connect-stripe-email';
export const TRANSITION_PUBLISH_LISTING_EMAIL = 'transition/publish-listing-email';

// Error Codes
export const ERROR_CODE_TRANSACTION_LISTING_NOT_FOUND = 'transaction-listing-not-found';
export const ERROR_CODE_TRANSACTION_INVALID_TRANSITION = 'transaction-invalid-transition';
export const ERROR_CODE_TRANSACTION_ALREADY_REVIEWED_BY_CUSTOMER =
  'transaction-already-reviewed-by-customer';
export const ERROR_CODE_TRANSACTION_ALREADY_REVIEWED_BY_PROVIDER =
  'transaction-already-reviewed-by-provider';
export const ERROR_CODE_TRANSACTION_BOOKING_TIME_NOT_AVAILABLE =
  'transaction-booking-time-not-available';
export const ERROR_CODE_PAYMENT_FAILED = 'transaction-payment-failed';
export const ERROR_CODE_CHARGE_ZERO_PAYIN = 'transaction-charge-zero-payin';
export const ERROR_CODE_CHARGE_ZERO_PAYOUT = 'transaction-charge-zero-payout';
export const ERROR_CODE_EMAIL_TAKEN = 'email-taken';
export const ERROR_CODE_EMAIL_NOT_FOUND = 'email-not-found';
export const ERROR_CODE_TOO_MANY_VERIFICATION_REQUESTS = 'email-too-many-verification-requests';
export const ERROR_CODE_UPLOAD_OVER_LIMIT = 'request-upload-over-limit';
export const ERROR_CODE_VALIDATION_INVALID_PARAMS = 'validation-invalid-params';
export const ERROR_CODE_VALIDATION_INVALID_VALUE = 'validation-invalid-value';
export const ERROR_CODE_NOT_FOUND = 'not-found';
export const ERROR_CODE_FORBIDDEN = 'forbidden';
export const ERROR_CODE_MISSING_STRIPE_ACCOUNT = 'transaction-missing-stripe-account';
