import { API_TYPE } from './api-type';
export const LOG_IN = {
    method: API_TYPE.POST,
    endpoint: 'login',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const USER_CREATE = {
    method: API_TYPE.POST,
    endpoint: 'current_user/create',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const CURRENT_USER_SHOW = {
    method: API_TYPE.GET,
    endpoint: 'current_user/show',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const CURRENT_USER_UPDATE = {
    method: API_TYPE.POST,
    endpoint: 'current_user/update_profile',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const VERIFY_EMAIL = {
    method: API_TYPE.POST,
    endpoint: 'current_user/verify_email',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const LOGIN_AS_USER = {
    method: API_TYPE.POST,
    endpoint: 'login/asUser',
    isFake: false,
    fakeResponsePath: 'assets/json/login',
    // isAdminApi: true
};

export const GENERATE_ACCOUNT_TOKEN = {
    method: API_TYPE.POST,
    endpoint: 'generate/accountToken',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const FETCH_STRIPE_DETAILS = {
    method: API_TYPE.GET,
    endpoint: 'stripe_account/fetch',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const GENERATE_BANK_ACCOUNT_TOKEN = {
    method: API_TYPE.POST,
    endpoint: 'create_stripe_account',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const STRIPE_ACCOUNT_CREATE = {
    method: API_TYPE.POST,
    endpoint: 'stripe_account/create',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const STRIPE_ACCOUNT_UPDATE = {
    method: API_TYPE.POST,
    endpoint: 'stripe_account/update',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const RESEND_EMAIL_VERIFICATION = {
    method: API_TYPE.POST,
    endpoint: 'current_user/send_verification_email',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const CHANGE_PASSWORD = {
    method: API_TYPE.POST,
    endpoint: '/current_user/change_password',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const RESEND_PHONE_VERIFICATION = {
    method: API_TYPE.POST,
    endpoint: 'resend/verification-phone',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const RESET_NOTIFICATION = {
    method: API_TYPE.POST,
    endpoint: 'reset/notifications',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const FORGET_PASSWORD = {
    method: API_TYPE.POST,
    endpoint: 'password_reset/request',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const RESET_PASSWORD = {
    method: API_TYPE.POST,
    endpoint: 'password_reset/reset',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const RESET_PASSWORD2 = {
    method: API_TYPE.POST,
    endpoint: 'reset-password',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const RESET_PASSWORD1 = {
    method: API_TYPE.DELETE,
    endpoint: 'revoke',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const DEACTIVATE_ACCOUNT = {
    method: API_TYPE.POST,
    endpoint: 'deactivate/account',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};


// listing constant
export const OWN_LISTINGS_GET = {
    method: API_TYPE.GET,
    endpoint: 'own_listings/show',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const OWN_LISTINGS_QUERY = {
    method: API_TYPE.GET,
    endpoint: 'own_listings/query',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const OWN_LISTING_UPDATE = {
    method: API_TYPE.POST,
    endpoint: 'own_listings/update',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const OWN_LISTING_CREATE_DRAFT = {
    method: API_TYPE.POST,
    endpoint: 'own_listings/create_draft',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const OWN_LISTING_PUBLISH_DRAFT = {
    method: API_TYPE.POST,
    endpoint: 'own_listings/publish_draft',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const OWN_LISTING_DISCARD_DRAFT = {
    method: API_TYPE.POST,
    endpoint: 'own_listings/discard_draft',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const OWN_LISTING_OPEN = {
    method: API_TYPE.POST,
    endpoint: 'own_listings/open',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const OWN_LISTING_CLOSE = {
    method: API_TYPE.POST,
    endpoint: 'own_listings/close',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const LISTINGS_GET = {
    method: API_TYPE.GET,
    endpoint: 'listings/show',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const PROFILEINFO_GET = {
    method: API_TYPE.GET,
    endpoint: 'host/profile',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const LISTINGINFO_GET = {
    method: API_TYPE.GET,
    endpoint: 'host/listing',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const REVIEWSINFO_GET = {
    method: API_TYPE.GET,
    endpoint: 'host/reviews',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const HOSTPROFILE_GET = {
    method: API_TYPE.GET,
    endpoint: 'guest/profile',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const HOSTREVIEWS_GET = {
    method: API_TYPE.GET,
    endpoint: 'guest/reviews',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};


export const LISTINGS_QUERY = {
    method: API_TYPE.GET,
    endpoint: 'listings/query',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const AVAILABILITY_EXCEPTION_CREATE = {
    method: API_TYPE.POST,
    endpoint: 'availability_exceptions/create',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const AVAILABILITY_EXCEPTION_DELETE = {
    method: API_TYPE.POST,
    endpoint: 'availability_exceptions/delete',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const AVAILABILITY_EXCEPTION_QUERY= {
    method: API_TYPE.GET,
    endpoint: 'availability_exceptions/query',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const TIMESLOTS_QUERY= {
    method: API_TYPE.GET,
    endpoint: 'timeslots/query',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const SLOTS_QUERY= {
    method: API_TYPE.GET,
    endpoint: 'slots/query',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const BOOKING_REQUEST_PAYMENT= {
    method: API_TYPE.POST,
    endpoint: 'bookings/request_payment',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const BOOKING_CONFIRM_PAYMENT= {
    method: API_TYPE.POST,
    endpoint: 'bookings/confirm_payment',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const BOOKING_CONFIRM= {
    method: API_TYPE.POST,
    endpoint: '/bookings/accept',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const BOOKING_ACCEPT= {
    method: API_TYPE.POST,
    endpoint: 'bookings/accept',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const BOOKING_CANCEL= {
    method: API_TYPE.POST,
    endpoint: 'bookings/cancel',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const BOOKING_DECLINE= {
    method: API_TYPE.POST,
    endpoint: 'bookings/decline',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const BOOKING_EXPIRE= {
    method: API_TYPE.GET,
    endpoint: 'bookings/expire',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const BOOKING_REVIEW_SAVE = {
    method: API_TYPE.POST,
    endpoint: 'bookings/review',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const BOOKINGS_REVIEWS = {
    method: API_TYPE.GET,
    endpoint: 'bookings/reviews',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const BOOKING_TRANSACTIONS= {
    method: API_TYPE.GET,
    endpoint: 'bookings/transactions',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const BOOKING_REVIEWS_LIST = {
    method: API_TYPE.GET,
    endpoint: 'bookings/reviews',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const BOOKING_DETAIL = {
    method: API_TYPE.GET,
    endpoint: 'booking',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const DASHBOARD_BOOKINGS = {
    method: API_TYPE.GET,
    endpoint: 'bookings',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const OWN_BOOKING = {
    method: API_TYPE.GET,
    endpoint: 'own_bookings',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const LISTING_DASHBOARD = {
    method: API_TYPE.POST,
    endpoint: 'listings/dashboard',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const REVIEW_QUERY = {
    method: API_TYPE.GET,
    endpoint: 'bookings/reviews',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const CONTENT = {
    method: API_TYPE.GET,
    endpoint: 'content',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const LOGIN = {
    method: API_TYPE.POST,
    endpoint: 'login',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const INVESTOR_LOGIN = {
    method: API_TYPE.POST,
    endpoint: 'investor-admin',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const SIGNUP = {
    method: API_TYPE.POST,
    endpoint: 'user-signup',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const AGENT_SIGNUP = {
    method: API_TYPE.POST,
    endpoint: 'signup',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const STATES_GET = {
    method: API_TYPE.GET,
    endpoint: 'v3/states',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const ALL_COUNTY_GET = {
    method: API_TYPE.GET,
    endpoint: 'v3/counties-all',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const COUNTY_GET = {
    method: API_TYPE.GET,
    endpoint: 'counties',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const CITY_GET = {
    method: API_TYPE.GET,
    endpoint: 'cities',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const APPRAISAL_POST = {
    method: API_TYPE.POST,
    endpoint: 'v3/add-file',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const APPRAISAL_DELETE = {
    method: API_TYPE.DELETE,
    endpoint: 'v3/delete-file',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const CONTACTUS = {
    method: API_TYPE.POST,
    endpoint: 'contact_us',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const PERSONALINFO = {
    method: API_TYPE.PUT,
    endpoint: 'profile',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const VERIFY_PHONE = {
    method: API_TYPE.POST,
    endpoint: 'verify/phone',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const RESEND_VERIFY_PHONE = {
    method: API_TYPE.POST,
    endpoint: 'resend/verification-phone',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};


export const INVITE_USER = {
    method: API_TYPE.POST,
    endpoint: 'v2/invite-user',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const DELETE_INVITE = {
    method: API_TYPE.POST,
    endpoint: 'v2/delete-invite',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const CANCEL_SUBSCRIPTION = {
    method: API_TYPE.POST,
    endpoint: 'cancel-subscription',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const START_PRICING = {
    method: API_TYPE.POST,
    endpoint: 'v3/start-trial',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const INCOMPLETE_PRICING_EMAIL = {
    method: API_TYPE.POST,
    endpoint: 'v3/incomplete-pricing',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const START_PRICING_PAY = {
    method: API_TYPE.POST,
    endpoint: 'v3/start-pricing-payment',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const ADD_SUBSCRIPTION = {
    method: API_TYPE.POST,
    endpoint: 'add-subscription',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const FINISH_CALCULATION = {
    method: API_TYPE.POST,
    endpoint: 'v2/finish-calculation',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const CASH_FLOW = {
    method: API_TYPE.POST,
    endpoint: 'cash-flow',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const ADD_COMMENT = {
    method: API_TYPE.POST,
    endpoint: 'v2/add-comment',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const GET_IN_TOUCH = {
    method: API_TYPE.POST,
    endpoint: 'contact-us',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const CONNECT_APPARTMENT = {
    method: API_TYPE.POST,
    endpoint: 'v3/apartment-connect',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const CONNECT_JOIN_APPARTMENT = {
    method: API_TYPE.POST,
    endpoint: 'v3/apartment-join-contact-list',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const CHAT_WITH_PRO = {
    method: API_TYPE.POST,
    endpoint: 'v2/connect-to-pro',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const APARTMENT_PRICING = {
    method: API_TYPE.POST,
    endpoint: 'v3/apartment-start-pricing',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const AGENT_DASHBOARD = {
    method: API_TYPE.GET,
    endpoint: 'agent-dashboard',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const GET_PROPERTY = {
    method: API_TYPE.GET,
    endpoint: 'property-details',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const DELETE_PROPERTY = {
    method: API_TYPE.POST,
    endpoint: 'delete/property',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const STEP1_CALCULATIONS = {
    method: API_TYPE.GET,
    endpoint: 'step1-calculation',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const STEP2_CALCULATIONS = {
    method: API_TYPE.GET,
    endpoint: 'step2-data',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const STEP2_CALCULATIONS_SAVE = {
    method: API_TYPE.POST,
    endpoint: 'step2-calculation',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const STEP3_CALCULATIONS = {
    method: API_TYPE.GET,
    endpoint: 'step3-data',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const STEP3_CALCULATIONS_SAVE = {
    method: API_TYPE.POST,
    endpoint: 'step3-calculation',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const STEP4_CALCULATIONS = {
    method: API_TYPE.GET,
    endpoint: 'step4-data',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const STEP4_CALCULATIONS_SAVE = {
    method: API_TYPE.POST,
    endpoint: 'step4-calculation',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const USER_DASHBOARD = {
    method: API_TYPE.GET,
    endpoint: 'user-dashboard',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const PROFILE = {
    method: API_TYPE.PUT,
    endpoint: 'edit-profile',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const PROFILE_GET = {
    method: API_TYPE.GET,
    endpoint: 'profile',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const PROFILE_PUT = {
    method: API_TYPE.PUT,
    endpoint: 'profile',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const COMP_DATA_GET = {
    method: API_TYPE.GET,
    endpoint: 'comp-data',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const COMP_DATA_ADD = {
    method: API_TYPE.POST,
    endpoint: 'add-comp-prop',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const USER_COMPED = {
    method: API_TYPE.GET,
    endpoint: 'user-compd',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const APARTMENT_DATA = {
    method: API_TYPE.GET,
    endpoint: 'apartment-data',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const APARTMENT_CALCULATION = {
    method: API_TYPE.POST,
    endpoint: 'apartment-calculation',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const REVIEW_PAGE = {
    method: API_TYPE.GET,
    endpoint: 'review-page',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const COMPLETE_CALCULATION = {
    method: API_TYPE.GET,
    endpoint: 'v2/complete-calculation',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const EDIT_PROPERTY = {
    method: API_TYPE.PUT,
    endpoint: 'edit-property',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const TAKE_SHORTCUT = {
    method: API_TYPE.POST,
    endpoint: 'take-shortcut',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const HOMEEASE_OPINION = {
    method: API_TYPE.POST,
    endpoint: 'homey-opinion',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const HOMEEASE_OPINION_PAYMENT = {
    method: API_TYPE.POST,
    endpoint: 'homey-opinion-payment',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const REMOVE_PROP = {
    method: API_TYPE.POST,
    endpoint: 'remove/prop',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const CONTENT_GET = {
    method: API_TYPE.GET,
    endpoint: 'content',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const RESEND_VERIFY_EMAIL = {
    method: API_TYPE.PUT,
    endpoint: 'user/resend-verify-email',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const PROFILE_DEACTIVATE = {
    method: API_TYPE.DELETE,
    endpoint: 'user',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

// Unread Message
export const UNREAD_MESSAGE = {
    method: API_TYPE.GET,
    endpoint: 'unread/msg',
    isFake: false,
    fakeResponsePath: 'assets/json/login',
    isChatApi: true
};

export const UNREAD_MESSAGE_COUNT = {
    method: API_TYPE.GET,
    endpoint: 'chat/unread_count',
    isFake: false,
    fakeResponsePath: 'assets/json/login',
    isChatApi: true
};



//Initiate Message
export const CHAT_INITIATE = {
    method: API_TYPE.POST,
    endpoint: 'init/message',
    isFake: false,
    fakeResponsePath: 'assets/json/login',
    isChatApi: true
};

// chat start
// Chat List
export const CHAT_LIST = {
    method: API_TYPE.GET,
    endpoint: 'chat/listing',
    isFake: false,
    fakeResponsePath: 'assets/json/login',
    isChatApi: true
};
export const CHAT_EXIST = {
    method: API_TYPE.GET,
    endpoint: 'chat/exist',
    isFake: false,
    fakeResponsePath: 'assets/json/login',
    isChatApi: true
};

// One_2_One message
export const CHAT_MESSAGE_ONE_2_ONE = {
    method: API_TYPE.GET,
    endpoint: 'chat/messages',
    isFake: false,
    fakeResponsePath: 'assets/json/login',
    isChatApi: true
};

// Send Message
export const SEND_MESSAGE = {
    method: API_TYPE.POST,
    endpoint: 'send/message',
    isFake: false,
    fakeResponsePath: 'assets/json/login',
    isChatApi: true
};


// Delete message
export const CHAT_DELETE_MESSAGE = {
    method: API_TYPE.DELETE,
    endpoint: 'chat/message',
    isFake: false,
    fakeResponsePath: 'assets/json/login',
    isChatApi: true
};


/**
 * Excel sheet update api const
 */
// Delete message
export const ADD_DATA_TO_SHEET = {
    method: API_TYPE.POST,
    endpoint: 'sheet/add',
    isFake: false,
    fakeResponsePath: 'assets/json/login',
    isChatApi: true
};

/**
 * Genrate bank account token
 * MKR
 */
export const GENERATE_BANK_TOKEN = {
    method: API_TYPE.POST,
    endpoint: 'generate/bankAccountToken',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};