import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  private _homeFilterDataSubject = new BehaviorSubject('default message')
  homeFilterDataObservable$ = this._homeFilterDataSubject.asObservable();

  private _profileDataSubject = new BehaviorSubject('default message')
  profileDataObservable$ = this._profileDataSubject.asObservable();


  private _userLoginOrNotSub = new BehaviorSubject(false)
  userLoginOrNotObservable$ = this._userLoginOrNotSub.asObservable();


  sendHomeFilterData2Detail(message:string){
    this._homeFilterDataSubject.next(message);
  }


  updateProfileEverywhere(message:string){
    
    this._profileDataSubject.next(message);
  }
 

  checkUserLoginOrNot(message:boolean){
    this._userLoginOrNotSub.next(message);
  }

    constructor() { }

}