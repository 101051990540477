import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './@core/@services/common.service';
import { DataService } from './@core/@services/data.service1';
import { AccountService } from './@view/account/account.service';
import { SubjectService } from './shared/service/subject.service';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'horseBnb';
  user: any;
  constructor(private accountService: AccountService,
    private ss: SubjectService,
    private router: Router, private cs: CommonService, private dataService: DataService, private meta: Meta) {
  }
  ngOnInit() {
    this.meta.addTag({ name: 'author', content: 'HorseBnB' });
    this.meta.addTag({ name: 'url', content: 'https://horsebnb.com/' });
    this.meta.updateTag({ name: 'title', content: 'HorseBnB' })
    // this.meta.updateTag({ name: 'keyword', content: 'horseBnb' });
    // this.meta.updateTag({ name: 'description', content: 'horseBnb_descrption' });
    // this.meta.updateTag({ name: 'type', content: 'image_type' });
    // this.meta.updateTag({name:'og:image',content:'horsebnb_image'})
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    // Do something
    let session = this.cs.getJsonCookie('horsebnbuser');
    if (session) {
      this.user = session.attributes;
      this.showUser()
    }
  }

  showUser() {
    // requires authenticated user

    let loginSub = this.accountService.getUserData({}).subscribe(
      (res) => {
        console.log(res);
        let logged_in_user: any = this.cs.getJsonCookie('horsebnbuser');
        logged_in_user.attributes = res.data.attributes;
        // logged_in_user.data.userId = res.data.id.uuid;

        this.cs.setStrCookie('horsebnbuser', logged_in_user);
        this.user = res.data.attributes;
        console.log(this.user);
        this.ss.updateProfileEverywhere(res.data.attributes);
        // res.data contains the response data
      },
      (err) => {
        console.log(err);
      }
    );
  }

}
