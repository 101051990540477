import {
  HttpErrorResponse, HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map, timeout } from 'rxjs/operators';
import { CustomApiService } from '../@services/api.service';
import { CustomToastService } from '../@services/toast.service';
import { DataService } from 'src/app/@core/@services/data.service1';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private apiService: CustomApiService, private router: Router, private toastService: CustomToastService, private dataService: DataService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let authReq;

    if (request.headers.get('skipHeader')) {
      authReq = request.clone();
    }
    else {
      authReq = request.clone({
        headers: this.apiService.getHeader(),
      });
    }

    return next.handle(authReq).pipe(
      timeout(60000),
      catchError((err) => this.handleError(err)),
      map((res: HttpResponse<any>) => res)
    );
  }

  handleError(err: HttpErrorResponse) {
    if (err.status == 401) {

      if (err?.error?.error_description)
        this.toastService.showError(err?.error?.error_description, 'Unauthorized');
      this.apiService.unauthorized();
      // } else if (err.status == 0 || !navigator.onLine) {
      //   this.toastService.showError(
      //     'Unkown Error',
      //     'Please Check Your Internet Connection'
      //   );
    } else if (
      err.status == 500 ||
      err.status == 501 ||
      err.status == 502 ||
      err.status == 503 ||
      err.status == 504 ||
      err.status == 505
    ) {
      this.toastService.showError('Something Went Wrong on Server', 'Error');
    } else if (err.status == 404) {
      this.toastService.showError('Not Found', 'Error');
    } else if (err.status == 403) {
      // this.apiService.Forbidden_logout()
      //   this.dataService.changeHeader(true);
      // this.dataService.changeLogin(true);
      // // });

      this.toastService.showError('Not Autheriod! Please login first.', 'Error');
      this.dataService.changeForbidden(true);
      this.router.navigate(['']);

    } else {
      this.toastService.showError(err.error.error_description, 'Error',);
    }
    return throwError(err);
  }
}
