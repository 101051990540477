import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, timeout } from 'rxjs/operators';
import { CommonService } from 'src/app/@core/@services/common.service';
import { CustomToastService } from 'src/app/@core/@services/toast.service';
import { ADMIN_BASE_URL, BASE_URL, CHAT_BASE_URL, UPLOAD_IMAGE_URL } from 'src/app/@core/@utills/constant';
import { DataService } from 'src/app/shared/service/data.service';
import { API_TYPE } from '../@utills/api-type';

@Injectable({
  providedIn: 'root',
})
export class CustomApiService {
  public serverURL = BASE_URL;
  public adminServerURL = ADMIN_BASE_URL;

  public chatServerURL = CHAT_BASE_URL;
  
  public uploadImage = UPLOAD_IMAGE_URL;
  
  noWhitespaceValidator: ValidatorFn;
  user: any;
  redirectUrl: string;
  constructor(
    private http: HttpClient,
    private toastService: CustomToastService,
    private dataService: DataService,
    private cookie: CookieService,
    private cs : CommonService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private router: Router
  ) {
    let sessionUser = this.cs.getJsonCookie('horsebnbuser');
    if (sessionUser) {
      this.user = sessionUser;
    }
  }

  // getHeader() {
  //   const session = JSON.parse(localStorage.getItem('session'));
  //   const headers = new HttpHeaders()
  //     .set('Authorization', session ? session.token : '')
  //     .set('Content-Type', 'application/json');
  //   console.log(headers);

  //   return headers;
  // }

  getHeader() {
    let token = this.cookie.get('horsebnbtoken') || '';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token,
    });
    return headers;
  }
  getUrl(requestConfig) {
    if (requestConfig.isFake) {
      return "http://app-transfer.com:3006/" + requestConfig.endpoint;
    } 
    else if (requestConfig.isChatApi)
      return this.chatServerURL + requestConfig.endpoint;
    else if(requestConfig.isAdminApi)
      return this.adminServerURL + requestConfig.endpoint;
    else return this.serverURL + requestConfig.endpoint;
  }

  checkProfileCompleted() {
    const session = JSON.parse(localStorage.getItem('session'));
    const is_profile_completed = session.user.is_profile_completed;
    if (is_profile_completed) {
      return true;
    } else {
      return false;
    }
  }

  checkStripeConnected() {
    const session = JSON.parse(localStorage.getItem('session'));
    const is_stripe_connected = session.user.is_stripe_connected;
    if (is_stripe_connected) {
      return true;
    } else {
      return false;
    }
  }

  httpRequest(requestConfig, data, isAdminApi?): Observable<any> {
    if (requestConfig.method == API_TYPE.GET) {
      return this.http.get(this.getUrl(requestConfig), {
        params: data,
      });
    } else if (requestConfig.method == API_TYPE.POST) {
      return this.http.post(this.getUrl(requestConfig), data, {
        headers: this.getHeader(),
      });
    } else if (requestConfig.method == API_TYPE.PUT) {
      return this.http.put(this.getUrl(requestConfig), data, {
        headers: this.getHeader(),
      });
    } else if (requestConfig.method == API_TYPE.DELETE) {
      const data1 = {
        body: data,
        headers: this.getHeader(),
      };
      return this.http.delete(this.getUrl(requestConfig), data1);
    }
  }


  unauthorized() {
    this.logout(true);
    this.router.navigate(['']);
    this.dataService.changeForbidden(true)
  }

  logout(isUnautherize?) {  
    let sessionUser = this.cs.getJsonCookie('horsebnbuser');
    if (sessionUser) {
      this.user = sessionUser;
    }
    return new Promise((resolve, error) => {
      let auth: any = this.getCookie('auth');
      if (auth == 0) {
      }
      this.redirectUrl = this.cs.getCookie("redirectUrl");
     
      this.delAllCookie().then((res) => {

        this.cs.setCookie("redirectUrl", this.redirectUrl)
        
        if(!isUnautherize)
        this.toastService.showSuccess(
          'User Logged out Successfully',
          'Logged Out'
        );
      }).catch(function (err) {
        // some code that throws an error
        console.log(err);
    })

      localStorage.clear();

      this.router.navigate(['']);
      resolve(true);
    });
  }
  
  Forbidden_logout() {
    // return new Promise((resolve, error) => {
      let auth: any = this.getCookie('auth');
      if (auth == 0) {
      }
      this.delAllCookie().then((res) => {
        this.toastService.showError('Not Allowed', 'Not Authorized to access this Page');
        this.router.navigate(['']);
      }).catch(function (err) {
        // some code that throws an error
        console.log(err);
    })
      // resolve(true);
    // });
  }

  get checkCookie() {
    let cookie = this.cookie.getAll();
    return cookie;
  }
  i = 0;
  delAllCookie() {
    return new Promise((res, err) => {
      let cookies = this.cookie.getAll();
      this.i++;
      for (const key in cookies) {
        if (cookies.hasOwnProperty(key)) {
          this.delCookie(key);
        }
      }
      this.cookie.removeAll();
      
      if (Object.keys(this.checkCookie).length == 0) {
        res(true);
      } 
      res(true);
      // else {
      //   if (this.i <= 100) {
      //     this.delAllCookie().then((res) => {
           
      //     }).catch(function (err) {
      //       // some code that throws an error
      //       console.log(err);
      //   })
      //   } else {
      //     err(true);
      //   }
      // }
    });
  }
  delCookie(name) {
    return this.cookie.remove(name);
  }

  uploadMedia(data)  {

    // let request: any = data;
    let request = new FormData();
    request.append("image", data);
    let token = this.cookie.get('horsebnbtoken') || '';
  
    const headers = new HttpHeaders().set('Authorization', token ? token : '').set('content-type','multipart/form-data');
  
    // return this.http.get<any>(this.serverURL + '/homeFeed',{headers:headers});
    return this.http.post('http://3.136.247.60:3000/api/upload-image', request, {
      headers,reportProgress: true,
      observe: "events" }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map((res:any) => {
        return res;
      })
    );
  }
  uploadMedia1(data)  {
    // let request: any = data;
    let request = new FormData();
    request.append("image", data);
   
    return this.http.post("http://3.136.247.60:3000/api/upload-image", request, {
      headers: this.getHeader(),
      reportProgress: true,
      observe: "events" }).pipe(
      // if responce takes more than one minute
      timeout(60000),
      // ...errors if any
      // catchError(this.handleError),
      // ...and calling .json() on the response to return data
      map((res:any) => {
        return res;
      })
    );
  }

  setCookie(name: string, value: string) {
    this.cookie.put(name, value, {
      path: '/',
    });
  }

  setStrCookie(name: string, value) {
    value = JSON.stringify(value);
    this.cookie.put(name, value, {
      path: '/',
    });
  }

  getCookie(name: string) {
    return this.cookie.get(name);
  }

  getJSONCookie(name: string) {
    const val = this.cookie.get(name);
    return JSON.parse(val);
  }

  deleteAllCookies() {
    this.cookie.removeAll({
      path: '/',
    });
  }
  setTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data.title) {
              return child.snapshot.data.title;
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.setTitleValue(data);
        }
      });
  }

  setTitleValue(val: string) {
    this.titleService.setTitle('Potswork : ' + val);
  }

   /*
     * handleError function for HTTP error handling
     */
    private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error("Client Side Error :", errorResponse.error.message);
      } else {
        console.error("Server Side Error :", errorResponse);
  
            // Swal.fire(errorResponse.error.error_description);
  
  
      }
      // return an observable with a meaningful error message to the end user
      return throwError(
        errorResponse
        // "There is a problem with the service. We are notified & working on it. Please try again later."
      );
    }
}
