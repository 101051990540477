import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./@theme/layouts/basic/basic.module').then((m) => m.BasicModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./@theme/layouts/complete-layout/complete-layout.module').then((m) => m.CompleteLayoutModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./@theme/layouts/header-only/header-only.module').then((m) => m.HeaderOnlyModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./@theme/layouts/with-footer/with-footer.module').then((m) => m.WithFooterModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./@theme/layouts/without-footer/without-footer.module').then((m) => m.WithoutFooterModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./@theme/layouts/blank/blank.module').then((m) => m.BlankModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// flex-cli search set --key type --type long --scope public -m 5ebb1454-bd2b-433c-be87-2de489508730
