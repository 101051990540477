import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  updateDetails = new Subject<boolean>();

  public loginSubject = new BehaviorSubject<boolean>(false);
  loginSubjectObservable = this.loginSubject.asObservable();

  public unreadMsgSubject = new BehaviorSubject<boolean>(false);
  unreadMsgSubjectObservable = this.unreadMsgSubject.asObservable();

  public headerSubject = new BehaviorSubject<boolean>(false);
  headerSubjectObservable = this.headerSubject.asObservable();
  
  public forbiddenSubject = new BehaviorSubject<boolean>(false);
  forbiddenSubjectObservable = this.forbiddenSubject.asObservable();

  public updateProfileData = new BehaviorSubject<boolean>(false);
  updateProfileDataObservable = this.updateProfileData.asObservable();


  public loaderData = new BehaviorSubject<boolean>(false);
  loaderDataObservable = this.loaderData.asObservable();

  constructor(){}
  
  changeLogin(value): void {
    this.loginSubject.next(value);
  }
  changeunreadMsg(value): void {
    this.unreadMsgSubject.next(value);
  }
  changeForbidden(value): void {
    this.forbiddenSubject.next(value);
  }
  changeProfileData(value): void {
    this.updateProfileData.next(value);
  }
  changeHeader(value): void {
    this.headerSubject.next(value);
  }
  changeLoader(value): void {
    this.loaderData.next(value);
  }
  
}